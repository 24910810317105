import React, { useEffect, useCallback, useState } from 'react';
import 'antd/dist/antd.css';
import { Layout, Menu, Button, Dropdown, Drawer, Radio, MenuProps } from 'antd';
import {
    MenuUnfoldOutlined,
    MenuFoldOutlined,
    UserOutlined,
    DownOutlined,
    PieChartOutlined,
    SolutionOutlined,
    LogoutOutlined,
    RetweetOutlined,
    MobileOutlined,
    UsergroupAddOutlined,
    MessageOutlined,
    TeamOutlined,
    MenuOutlined,
    FileTextOutlined
} from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import * as GlobalActions from '../../store/modules/global/actions';
import * as LoginActions from '../../store/modules/login/actions';
import { useHistory } from 'react-router-dom';
import { stateGlobal } from '../../interfaces/Global';
import Icon from '@ant-design/icons';
import { FaSitemap, FaCog, FaUnlockAlt, FaTv, FaRegListAlt, FaListAlt, FaUserAlt, FaLockOpen } from 'react-icons/fa';
import { ReactComponent as FaPenSquare } from '../../assets/imagsIconesModulos/pen-to-square.svg';

// #@Natal
// // @ts-ignore
// import GIFLogoDark from '../../assets/GIF_LOGO_NATAL_DARK_PAINEL.gif';
// // @ts-ignore
// import GIFLogo from '../../assets/GIF_LOGO_NATAL_BLUE_PAINEL.gif';

// @ts-ignore
import ImagemLogo from '../../assets/logo.png';
// @ts-ignore
import ImagemIcone from '../../assets/icone.png';

// const { Title } = Typography;

const { Header, Sider, Content, Footer } = Layout;
const { SubMenu } = Menu;

const IconHand = () => <Icon style={{ fontSize: 17 }} component={FaSitemap} ></Icon>
const IconCogs = (props: any) => <Icon {...props} component={FaCog} ></Icon>
const IconTv = (props: any) => <Icon {...props} component={FaTv} ></Icon>
const UnlokProcessoJuridico = (props: any) => <Icon {...props} component={FaUnlockAlt} ></Icon>
const IconFaClipboardList = (props: any) => <Icon {...props} component={FaRegListAlt} ></Icon>
const IconFaPenSquare = (props: any) => <Icon {...props} component={FaPenSquare} ></Icon>

type MenuItem = Required<MenuProps>['items'][number];

function getItem(
    label: React.ReactNode,
    key?: React.Key | null,
    icon?: React.ReactNode,
    children?: MenuItem[],
    type?: 'group',
): MenuItem {
    return {
        key,
        icon,
        children,
        label,
        type,
    } as MenuItem;
}

{/* <Menu.Item disabled key="1" icon={<UserOutlined />} >Perfil</Menu.Item>
            <Menu.Item disabled key="2" icon={<RetweetOutlined />} >Alterar Senha</Menu.Item>
            <Menu.Item onClick={clickMenuDireitoConfiguracoes} icon={<IconCogs />} key="3">Configurações</Menu.Item>
            <Menu.Item onClick={onAbrirDrawerColor} key="4" icon={<IconTv />} >Tema</Menu.Item>
            <Menu.Item onClick={clickMenuDireitoSair} key="5" icon={<LogoutOutlined />} >Sair</Menu.Item> */}

const itemsMenuConfiguracao: MenuItem[] = [
    // getItem('Perfil', '1', <UserOutlined />),
    // getItem('Alterar Senha', '2', <RetweetOutlined />),
    getItem('Configurações', '3', <IconCogs />),
    getItem('Tema', '4', <IconTv />),
    getItem('Sair', '5', <LogoutOutlined />),

];

const itemsMenuPainel: MenuItem[] = [
    // getItem('Inicio', '1', <PieChartOutlined />),
    getItem('Atendimentos', '2', <TeamOutlined />),
    getItem('Outros Atendimentos', '2outrosAtendimentos', <TeamOutlined />),
    getItem('Usuários', '3', <UserOutlined />),
    getItem('Contratos', 'subContratos', <SolutionOutlined />, [
        getItem('Listagem de Contratos', '4', <FaListAlt />),
        getItem('Entidades', '12', <FaListAlt />),
        getItem('Painel Gestor', 'sub3', <FaListAlt />, [
            getItem('Perfis de Usuários', '13', <FaUserAlt />),
            getItem('Permissões de Usuários', '14', <FaLockOpen />),
        ]),
    ]),
    getItem('Municípios por Contrato', '11', <IconFaClipboardList />),
    getItem('Processos Jurídicos (Senha)', '5', <UnlokProcessoJuridico />),
    getItem('Relatórios', 'subRelatorios', <MenuOutlined />, [
        getItem('Acessos', 'RelAcessos', <FileTextOutlined />),
        getItem('Dispositivos Ativos', '10', <MobileOutlined />),
        getItem('Pendências de Municípios', 'RelatorioPendenciasMunicipios', <FileTextOutlined />),
    ]),
    getItem('Mensagens', '9', <MessageOutlined />),
    getItem('Códigos Autenticação', '7', <MobileOutlined />),
    getItem('Pesquisa de Satisfação', 'pesquisaDeSatisfacao', <IconFaPenSquare />),
    getItem('Associações', '6', <IconHand />),
    getItem('Integrações', 'integracoes', <IconHand />),
];

const LayoutDashboard: React.FC = ({ children }) => {

    const dispatch = useDispatch();
    const propsState = useSelector((state: stateGlobal) => state.global);
    const history = useHistory();
    const largura = window.innerWidth;

    const [collapsed, setCollapsed] = useState(false);
    const [collapsedWidth, setCollapsedWidth] = useState<number>(77);
    const [visibleDrawer, setvisibleDrawer] = useState(false);
    const [visibleDrawerColor, setVisibleDrawerColor] = useState(false);

    const [abrirMenuLateral, setAbrirMenuLateral] = useState<Array<string>>(['']);

    const onCloseDrawerColor = useCallback((): void => {
        setVisibleDrawerColor(false);
    }, []);

    const onAbrirDrawerColor = useCallback((): void => {
        setVisibleDrawerColor(true);
    }, []);

    const onchangeRadioTheme = useCallback((value: any): void => {

        dispatch(GlobalActions.updateTheme(value.target.value));

        history.go(0);
    }, []);

    useEffect(() => {
        let arrayUrl = window.location.pathname.split("/");

        switch (window.location.pathname) {
            case "/dashboard":
                dispatch(GlobalActions.updatePositionMenu('1'));
                setAbrirMenuLateral([''])
                break;
            case "/atendimentos":
                dispatch(GlobalActions.updatePositionMenu('2'));
                setAbrirMenuLateral([''])
                break;
            case "/atendimentos/adicionar":
                dispatch(GlobalActions.updatePositionMenu('2'));
                setAbrirMenuLateral([''])
                break;
            case "/atendimentos/editar/" + arrayUrl[3]:
                dispatch(GlobalActions.updatePositionMenu('2'));
                setAbrirMenuLateral([''])
                break;
            case "/outros-atendimentos":
                dispatch(GlobalActions.updatePositionMenu('2outrosAtendimentos'));
                setAbrirMenuLateral([''])
                break;
            case "/outros-atendimentos/adicionar":
                dispatch(GlobalActions.updatePositionMenu('2outrosAtendimentos'));
                setAbrirMenuLateral([''])
                break;
            case "/outros-atendimentos/editar/" + arrayUrl[3]:
                dispatch(GlobalActions.updatePositionMenu('2outrosAtendimentos'));
                setAbrirMenuLateral([''])
                break;
            case "/usuarios":
                dispatch(GlobalActions.updatePositionMenu('3'));
                setAbrirMenuLateral([''])
                break;
            case "/usuarios/adicionar":
                dispatch(GlobalActions.updatePositionMenu('3'));
                setAbrirMenuLateral([''])
                break;
            case "/contrato":
                dispatch(GlobalActions.updatePositionMenu('4'));
                setAbrirMenuLateral(['subContratos'])
                break;
            case "/contrato/adicionar":
                dispatch(GlobalActions.updatePositionMenu('4'));
                setAbrirMenuLateral(['subContratos'])
                break;
            case "/cidadesUser":
                dispatch(GlobalActions.updatePositionMenu('5'));
                setAbrirMenuLateral([''])
                break;
            case "/cidadesUser/adicionar":
                dispatch(GlobalActions.updatePositionMenu('5'));
                setAbrirMenuLateral([''])
                break;
            case "/associacao":
                dispatch(GlobalActions.updatePositionMenu('6'));
                setAbrirMenuLateral([''])
                break;
            case "/associacao/adicionar":
                dispatch(GlobalActions.updatePositionMenu('6'));
                setAbrirMenuLateral([''])
                break;
            case "/liscodigosms":
                dispatch(GlobalActions.updatePositionMenu('7'));
                setAbrirMenuLateral([''])
                break;
            case "/listcaptacaousuario":
                dispatch(GlobalActions.updatePositionMenu('8'));
                setAbrirMenuLateral([''])
                break;
            case "/listcaptacaousuario/addobrigacao":
                dispatch(GlobalActions.updatePositionMenu('8'));
                setAbrirMenuLateral([''])
                break;
            case "/mensagens":
                dispatch(GlobalActions.updatePositionMenu('9'));
                setAbrirMenuLateral([''])
                break;
            case "/mensagens/add":
                dispatch(GlobalActions.updatePositionMenu('9'));
                setAbrirMenuLateral([''])
                break;
            case "/relatorios/dispositivosativos":
                dispatch(GlobalActions.updatePositionMenu('10'));
                setAbrirMenuLateral(['subRelatorios'])
                break;
            case "/contrato/municipios":
                dispatch(GlobalActions.updatePositionMenu('11'));
                setAbrirMenuLateral([''])
                break;
            case "/contrato/modulo/" + arrayUrl[3]:
                dispatch(GlobalActions.updatePositionMenu('12'));
                setAbrirMenuLateral(['subContratos'])
                break;
            case "/pesquisa-satisfacao":
                dispatch(GlobalActions.updatePositionMenu('pesquisaDeSatisfacao'));
                setAbrirMenuLateral([''])
                break;
            case "/pesquisa-satisfacao/criar":
                dispatch(GlobalActions.updatePositionMenu('pesquisaDeSatisfacao'));
                setAbrirMenuLateral([''])
                break;
            case "/contrato/entidades":
                dispatch(GlobalActions.updatePositionMenu('12'));
                setAbrirMenuLateral(['subContratos'])
                break;
            case "/contrato/entidades/gestor/perfil":
                dispatch(GlobalActions.updatePositionMenu('13'));
                setAbrirMenuLateral(['subContratos'])
                break;
            case "/contrato/entidades/gestor/permissao":
                dispatch(GlobalActions.updatePositionMenu('14'));
                setAbrirMenuLateral(['subContratos'])
                break;
            case "/relatorios/pendencias-municipios-por-estado":
                dispatch(GlobalActions.updatePositionMenu('RelatorioPendenciasMunicipios'));
                setAbrirMenuLateral(['subRelatorios'])
                break;
            case "/relatorios/acessos":
                dispatch(GlobalActions.updatePositionMenu('RelAcessos'));
                setAbrirMenuLateral(['subRelatorios'])
                break;
            case "/integracoes":
                dispatch(GlobalActions.updatePositionMenu('integracoes'));
                setAbrirMenuLateral([''])
                break;
            case '/integracoes/' + arrayUrl[2]:
                dispatch(GlobalActions.updatePositionMenu('integracoes'));
                setAbrirMenuLateral([''])
            case '/integracoes/sicon-personalizacao/' + arrayUrl[3]:
                dispatch(GlobalActions.updatePositionMenu('integracoes'));
                setAbrirMenuLateral([''])
                break;

            default:
                dispatch(GlobalActions.updatePositionMenu(''));
                break;
        }
    }, [window.location.pathname]);

    useEffect(() => {
        if (largura < 768) {
            setCollapsedWidth(0);
        }
    }, []);


    const clickNavigateMenuLateral = useCallback((rota: string): void => {
        history.push(rota);
        setvisibleDrawer(false);
    }, []);

    const clickMenuPrinciopal = useCallback(({ key }: any): void => {
        dispatch(GlobalActions.updatePositionMenu(key));

        switch (key) {
            case '1':
                clickNavigateMenuLateral("/dashboard");
                setAbrirMenuLateral([''])
                break;
            case '2':
                clickNavigateMenuLateral('/atendimentos');
                setAbrirMenuLateral([''])
                break;
            case '2outrosAtendimentos':
                clickNavigateMenuLateral('/outros-atendimentos');
                setAbrirMenuLateral([''])
                break;
            case '3':
                let arrayUrl = window.location.pathname.split("/");

                if (arrayUrl[1] === 'usuarios' && !arrayUrl[2]) {

                } else {

                    let dadosLocalStorage: string | null = localStorage.getItem('@GovFacil:ListUsersContrato');
                    let localUserData: any = null;
                    if (typeof dadosLocalStorage == 'string') {
                        localUserData = JSON.parse(dadosLocalStorage);
                    }

                    if (localUserData) {
                        localStorage.removeItem('@GovFacil:ListUsersContrato');
                    }
                    clickNavigateMenuLateral('/usuarios');
                    setAbrirMenuLateral([''])
                }
                setvisibleDrawer(false);
                break;
            case '4':
                clickNavigateMenuLateral('/contrato');
                setAbrirMenuLateral(['subContratos'])
                break;
            case '5':
                clickNavigateMenuLateral('/cidadesUser');
                setAbrirMenuLateral([''])
                break;
            case '6':
                clickNavigateMenuLateral('/associacao');
                setAbrirMenuLateral([''])
                break;
            case '7':
                clickNavigateMenuLateral('/liscodigosms');
                setAbrirMenuLateral([''])
                break;
                // case '8':
                //     clickNavigateMenuLateral('/listcaptacaousuario');
                setAbrirMenuLateral([''])
            //     break;
            case '9':
                clickNavigateMenuLateral('/mensagens');
                setAbrirMenuLateral([''])
                break;
            case '10':
                clickNavigateMenuLateral('/relatorios/dispositivosativos');
                setAbrirMenuLateral(['subRelatorios'])
                break;
            case '11':
                clickNavigateMenuLateral('/contrato/municipios');
                setAbrirMenuLateral([''])
                break;
            case 'pesquisaDeSatisfacao':
                clickNavigateMenuLateral('/pesquisa-satisfacao');
                setAbrirMenuLateral([''])
                break;
            case '12':
                clickNavigateMenuLateral('/contrato/entidades');
                setAbrirMenuLateral(['subContratos'])
                break;
            case '13':
                clickNavigateMenuLateral('/contrato/entidades/gestor/perfil');
                setAbrirMenuLateral(['subContratos'])
                break;
            case '14':
                clickNavigateMenuLateral('/contrato/entidades/gestor/permissao');
                setAbrirMenuLateral(['subContratos'])
                break;
            case 'RelatorioPendenciasMunicipios':
                clickNavigateMenuLateral('/relatorios/pendencias-municipios-por-estado');
                setAbrirMenuLateral(['subRelatorios'])
                break;
            case 'RelAcessos':
                clickNavigateMenuLateral('/relatorios/acessos');
                setAbrirMenuLateral(['subRelatorios'])
                break;
            case 'integracoes':
                let dadosLocalStorage: string | null = localStorage.getItem('@GovFacil:ListInteracoesByMunicipio');
                let localUserData: any = null;
                if (typeof dadosLocalStorage == 'string') {
                    localUserData = JSON.parse(dadosLocalStorage);
                }

                if (localUserData) {
                    localStorage.removeItem('@GovFacil:ListInteracoesByMunicipio');
                }
                clickNavigateMenuLateral('/integracoes');
                setAbrirMenuLateral([''])
                break;
            default:
                break;
        }

    }, []);

    const toggle = useCallback((): void => {
        if (collapsedWidth === 0) {
            setvisibleDrawer(!visibleDrawer);
        } else {
            setCollapsed(!collapsed);
        }
    }, [collapsedWidth, visibleDrawer, collapsed]);

    const onCollapse = useCallback((collapsedVal): void => {
        if (!collapsed) {
            setCollapsed(collapsedVal);
            setCollapsedWidth(77);
        } else {
            setCollapsed(collapsedVal);
            setCollapsedWidth(0);
        }
    }, [collapsed, collapsedWidth]);

    const onCloseDrawer = useCallback((): void => {
        setvisibleDrawer(false);
    }, []);

    const clickMenuDireitoConfiguracoes = useCallback((): void => {
        history.push("/configuracoesdosistema");
    }, []);

    const clickMenuDireitoSair = useCallback((): void => {
        history.push("/");
        localStorage.removeItem('@GovFacil:token');
        dispatch(LoginActions.loginAlterSingOut());
    }, []);

    const onClickMenuConfiguracoes = useCallback((e: any): void => {
        switch (e.key) {
            case '3':
                clickMenuDireitoConfiguracoes();
                break;
            case '4':
                onAbrirDrawerColor();
                break;
            case '5':
                clickMenuDireitoSair();
                break;

            default:
                break;
        }

    }, []);

    const menuPerfil = useCallback(() => (
        <Menu items={itemsMenuConfiguracao} onClick={onClickMenuConfiguracoes} />
    ), []);

    return (
        <>
            <Drawer
                placement="left"
                closable={false}
                onClose={onCloseDrawer}
                visible={visibleDrawer}
                key="left"
                style={{ padding: 0 }}
            // bodyStyle={{background:'red'}}
            >
                <img className="logoimgMenuResponsive" src={ImagemLogo}></img>
                <Menu mode="inline"
                    onClick={position => { clickMenuPrinciopal(position) }}
                    defaultSelectedKeys={[propsState.positionMenu]}
                    selectedKeys={[propsState.positionMenu]}
                    style={{ width: 262, marginLeft: -30, padding: 0 }}
                    items={itemsMenuPainel}
                    openKeys={abrirMenuLateral}
                    onOpenChange={(openKeys) => setAbrirMenuLateral(openKeys)}
                />
            </Drawer>
            <Layout style={{ minHeight: '100vh' }}>
                <Sider
                    width={280}
                    collapsedWidth={collapsedWidth}
                    breakpoint="md"
                    onBreakpoint={broken => {
                        onCollapse(broken);
                    }}
                    collapsed={collapsed}
                    style={{
                        overflow: 'auto',
                        height: '100vh',
                        position: 'fixed',
                        left: 0,
                    }}
                >
                    {/* <div className="logo" /> */}
                    {collapsed ?
                        <img className="iconePGimg" src={ImagemIcone}></img>
                        : <img className="logoimg" src={
                            ImagemLogo
                            // propsState.theme === 'light' ? GIFLogo : GIFLogoDark
                        }></img>}
                    <Menu theme="dark" mode="inline"
                        onClick={position => { clickMenuPrinciopal(position) }}
                        defaultSelectedKeys={[propsState.positionMenu]}
                        selectedKeys={[propsState.positionMenu]}
                        style={{}}
                        items={itemsMenuPainel}
                        openKeys={abrirMenuLateral}
                        onOpenChange={(openKeys) => setAbrirMenuLateral(openKeys)}
                    />

                </Sider>
                <Layout className={collapsed ? "siteLayout2" : "siteLayout"}>
                    <Header className="site-layout-sub-header-background"
                        style={{
                            position: 'fixed',
                            zIndex: 2,
                            padding: 0,
                            width: collapsed ? 'calc(100% - 75px)' : 'calc(100% - 280px)'
                        }}
                    >
                        <div style={{ float: "left" }}>
                            {React.createElement(
                                collapsed ? MenuUnfoldOutlined : MenuFoldOutlined,
                                {
                                    className: "trigger",
                                    onClick: toggle
                                }
                            )}
                        </div>
                        {/* <div className="divTitle">
              <Title level={4}>Bem vindo Mateus Chines</Title>
            </div> */}
                        <Dropdown className="dropDownMenu" trigger={['click']} overlay={menuPerfil}>
                            <Button type="text">
                                Opções <DownOutlined />
                            </Button>
                        </Dropdown>
                        {/* <Button type="text">Mateus Chines</Button> */}
                    </Header>
                    <Content className="site-layout-background" >
                        {children}
                    </Content>
                    <Footer style={{ textAlign: 'center', marginTop: -10 }}>Desenvolvido por <img className="logoimgFooter" src={ImagemLogo}></img></Footer>
                </Layout>
            </Layout>
            {/* Drawer para alterar cor do sistema */}
            <Drawer
                title="Tema do sistema"
                placement="right"
                closable={false}
                onClose={onCloseDrawerColor}
                visible={visibleDrawerColor}
            >
                <Radio.Group onChange={onchangeRadioTheme} defaultValue={propsState.theme} size="large">
                    <Radio.Button value="light">Light</Radio.Button>
                    <Radio.Button value="dark">Dark</Radio.Button>
                    {/* <Radio.Button value="c">Beijing</Radio.Button> */}
                </Radio.Group>
            </Drawer>
        </>
    );
};

export default LayoutDashboard;
